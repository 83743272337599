import { publish, subscribe } from "./observer";
import {
  openPopup,
  newsletterFormSuccess,
  newsletterFormFailure,
  newsletterFormIsMounted,
  recaptchaLoaded,
  closePopup,
} from "./observer-subjects";
import { pushNewsletterSubscription } from "./gtm-event";
import { recaptchaIsLoaded } from "./load-recaptcha";

const STATUS_SELECTOR = `.js-newsletter-status`;

const NewsletterForm = (form) => {
  let isSubmitting;

  const statusEl = form.querySelector(STATUS_SELECTOR);

  const button = form.querySelector('[type="submit"]');
  const recaptchaSiteKey = form.getAttribute("data-recaptcha-sitekey");

  const recaptchaAction = form.getAttribute("data-recaptcha-action"); // This can be an invalid captcha action, should have the format
  // Valid recaptcha action may only include "A-Za-z/_" characters,
  // but the recaptchaAction may include spaces and dashes.
  const validRecaptchaAction = recaptchaAction
    .replace(" ", "_")
    .replace("-", "_");

  const enableButton = () => {
    button.removeAttribute("disabled");
  };

  const submitForm = ({ action, data }) => {
    window.grecaptcha
      .execute(recaptchaSiteKey, { action: validRecaptchaAction })
      .then((token) => {
        // Post to newsletter lambda
        data.append("g-recaptcha-response", token);
        const response = fetch(action, {
          method: "POST",
          headers: { Accept: "application/json" },
          body: data,
        });

        return response;
      })
      .then((response) => {
        if (response.ok) {
          publish(newsletterFormSuccess);
        } else {
          publish(newsletterFormFailure);
        }
      })
      .catch((error) => {
        publish(newsletterFormFailure);
      });
  };

  const handleSubmit = () => {
    if (isSubmitting) {
      return;
    }
    isSubmitting = true;

    const action = form.getAttribute("action");
    // Reverse action after the action is also reversed in the HTML.
    const reversedAction = action.split("").reverse().join("");

    statusEl.classList.remove("has-succeeded", "has-failed");
    statusEl.setAttribute("aria-hidden", "true");
    form.classList.add("is-submitting");

    const formData = new FormData(form);
    const email = formData.get("email");
    pushNewsletterSubscription(email);

    submitForm({ action: reversedAction, data: new FormData(form) });
  };

  // Resolve the submitting status.
  const resolveSubmittingStatus = () => {
    form.classList.remove("is-submitting");
    isSubmitting = false;
  };

  // Handle a successful subscription.
  const submitFormSuccess = () => {
    const currentPopupId = form.getAttribute("data-current-popup-id");
    const successPopupId = form.getAttribute("data-success-popup-id");

    // Close current popup if the newsletter form is inside a popup.
    if (currentPopupId) {
      publish(closePopup, currentPopupId);
    }
    publish(openPopup, successPopupId); // Open successful registration popup

    window.setTimeout(() => {
      form.querySelector('input[type="email"]').value = "";
    }, 200);

    resolveSubmittingStatus();
  };

  // Handle a failing subscription.
  const submitFormFailure = () => {
    statusEl.innerHTML =
      "<span>Something went wrong, please try again...</span>";
    statusEl.classList.add("has-failed");
    statusEl.setAttribute("aria-hidden", "false");

    window.setTimeout((e) => {
      statusEl.setAttribute("aria-hidden", "true");
    }, 2000);

    resolveSubmittingStatus();
  };

  // Failsafe to only run function when this instance is submitting.
  const checkIfSubmitting = (fn) => {
    if (isSubmitting) {
      fn();
    }
  };

  return {
    init() {
      // Button is disabled in the initial html, enable it when recaptcha is loaded.
      if (recaptchaIsLoaded()) {
        enableButton();
      } else {
        subscribe(recaptchaLoaded, () => {
          enableButton();
        });
      }

      form.addEventListener("submit", (e) => {
        e.preventDefault();
        form.querySelector("button").blur();
        handleSubmit();
      });
      statusEl.addEventListener("click", (e) => {
        statusEl.setAttribute("aria-hidden", "true");
      });

      publish(newsletterFormIsMounted);
      subscribe(newsletterFormSuccess, () =>
        checkIfSubmitting(submitFormSuccess),
      );
      subscribe(newsletterFormFailure, () =>
        checkIfSubmitting(submitFormFailure),
      );
    },
  };
};

export const enhancer = (form) => {
  const newsletterForm = NewsletterForm(form);
  newsletterForm.init();
};
