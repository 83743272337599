import { publish } from "./observer";
import { recaptchaLoaded } from "./observer-subjects";

export function recaptchaIsLoaded() {
  return typeof window.grecaptcha !== "undefined";
}

function notifyWhenRecaptchaIsLoaded() {
  if (recaptchaIsLoaded()) {
    return publish(recaptchaLoaded);
  }
  setTimeout(notifyWhenRecaptchaIsLoaded, 100);

  return null;
}

function createScript(recaptchaSiteKey) {
  const script = document.createElement("script");
  script.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}`;
  script.async = true;
  script.defer = true;
  document.head.appendChild(script);
}

export const enhancer = (container) => {
  const recaptchaSiteKey = container.getAttribute("data-recaptcha-sitekey");
  createScript(recaptchaSiteKey);

  notifyWhenRecaptchaIsLoaded();
};
