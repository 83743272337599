export const teamMember = "team-member";

// MapBox map.
export const showInfoBox = "show-info-box";
export const hideInfoBox = "hide-info-box";
export const setHideInfoBox = "set-hide-info-box";
export const showMetaBox = "show-meta-box";

// Citizen science.
export const updateMarkerInfo = "citizen-science-update-marker-info";
export const updateClusterInfo = "citizen-science-update-cluster-info";
export const updateCitizenScienceData = "update-citizen-science-data";
export const citizenSciencePiePartMouseEnter = "citizen-science-pie-part-enter";
export const citizenSciencePiePartMouseLeave = "citizen-science-pie-part-leave";
export const hideOverlays = "citizen-science-hide-overlays";
export const citizenScienceSideBarShowOverview =
  "citizen-science-side-bar-show-overview";
export const citizenScienceSideBarShowSingle =
  "citizen-science-side-bar-show-single";

// Trash tracker.
export const trashTrackerSetLatLong = "trash-tracker-set-lat-long";
export const trashTrackerUpdateData = "trash-tracker-update-data";
export const trashTrackerResetData = "trash-tracker-reset-data";
export const trashTrackerIsLoading = "trash-tracker-is-loading";
export const trashTrackerError = "trash-tracker-error";
export const trashTrackerHasSubmitted = "trash-tracker-has-submitted";
export const trashTrackerIsDoneDrawing = "trash-tracker-is-done-drawing";
export const trashTrackerResetForm = "trash-tracker-reset-form";

// Popup.
export const openPopup = "open-popup";
export const closePopup = "close-popup";
export const forceClosePopup = "force-close-popup";

// systemsDashboard.
export const systemsDashboardReceivedData = "systems-dashboard-received-data";

// Tabs.
export const tabChange = "tab-change";

// Newsletter form.
export const newsletterFormSuccess = "newsletter-form-success";
export const newsletterFormFailure = "newsletter-form-failure";
export const newsletterFormIsMounted = "newsletter-form-is-mounted";

// Sunglasses block
export const sunglassesUpdate = "sunglasses-update";
export const sunglassesClose = "sunglasses-close";

// Sunglasses stories.
export const sunglassesStoriesReceivedData = "sunglasses-stories-received-data";

// In-page navigation.
export const updateInPageNavigationState = "in-page-navigation-state";
export const updateInPageNavigationActiveItem =
  "in-page-navigation-active-item";
export const closeInPageNavigation = "close-in-page-navigation";

// Play (popup) header video, not auto-playing header video.
export const playHeaderVideo = "play-header-video";
export const closeHeaderVideo = "close-header-video";

// Recaptcha.
export const recaptchaLoaded = "recaptcha-loaded";
